import axios from 'axios'

// 创建 axios 实例
const service = axios.create({
    baseURL: 'https://gxbapi.pluss.cn/api', // api base_url
    timeout: 1000 * 300, // 请求超时时间
    retry: 5,
    retryDelay: 1000,
})

// 定义执行错误函数
const errorfunc = (error) => {
    // console.log(error)
    return Promise.reject(error)
}

service.interceptors.request.use(config => {
    return config
}, errorfunc)

service.interceptors.response.use((response) => {
    let result = response.data
    return result
}, errorfunc)


export {
    service as axios
}
