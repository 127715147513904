import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import { axios } from "@/utils/request";
Vue.prototype.$axios = axios
import {Button, Popup,Icon,Image as VanImage,Swipe, SwipeItem,Lazyload } from 'vant';
import 'vant/lib/index.css'

const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("appVersion");
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload()
}

Vue.use(Popup);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Lazyload);
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
