import Vue from "vue";
import VueRouter from "vue-router";
// import Notes from '@/components/Notes'

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/JoinUs" },
  {
    path: "/Notes",
    name: "Notes",
    component: () => import(/* webpackChunkName: "Notes" */ "@/components/Notes"),
  },
  {
    path: "/Investors",
    name: "Investors",
    component: () => import(/* webpackChunkName: "Investors" */ "@/components/Investors"),
  },
  {
    path: "/GoodProjectDetail",
    name: "GoodProjectDetail",
    component: () => import(/* webpackChunkName: "GoodProjectDetail" */ "@/components/GoodProjectDetail"),
  },
  {
    path: "/IntroDuction",
    name: "IntroDuction",
    component: () => import(/* webpackChunkName: "IntroDuction" */ "@/components/IntroDuction"),
  },
  {
    path: "/JoinUs",
    name: "JoinUs",
    component: () => import("@/views/joinus/JoinUs"),
  },
  {
    path: "/Regist",
    name: "Regist",
    component: () => import("@/views/regist/Regist"),
  },{
    path: "/QrCode",
    name: "QrCode",
    component: () => import("@/views/qrcode/QrCode"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.length == 0) {
//     next('/')
//   } else {
//     next()
//   }
// })

export default router;
